import { defineStore } from 'pinia';
import { FirebaseToken, User } from 'src/classes/Firebase';
import { storageService } from 'src/boot/storage';
import { api } from 'src/boot/axios';
import {
  FUNCTION_URL_REFRESH_TOKEN,
  RefreshTokenRequest,
  RefreshTokenResponse,
} from 'src/classes/Cloudflare';

const USER_STORAGE_KEY = 'user';

export const useFirebaseStore = defineStore('firebase', {
  state: () => ({
    token: undefined as FirebaseToken | undefined,
    user: undefined as User | undefined,
  }),

  getters: {
    getToken(): FirebaseToken | undefined {
      return this.token;
    },
    isEmailVerified(): boolean | undefined {
      return this.token?.firebaseToken.email_verified;
    },
    currentUser(): User | undefined {
      return this.user;
    },
  },

  actions: {
    setFirebaseToken(firebaseToken: string) {
      this.token = new FirebaseToken(firebaseToken);
    },

    setEmailVerified(verified: boolean) {
      if (this.token?.firebaseToken) {
        this.token.firebaseToken.email_verified = verified;
      }
    },

    updateUser(user: User) {
      this.user = user;
      this.token = new FirebaseToken(user.idToken);
      storageService.setItem(USER_STORAGE_KEY, user);
    },

    async clear() {
      this.user = undefined;
      this.token = undefined;
      await storageService.removeItem(USER_STORAGE_KEY);
    },

    async loadUserFromStorage(): Promise<User | null> {
      const user: User | null = await storageService.getItem(USER_STORAGE_KEY);

      if (user) {
        this.updateUser(user);
      }

      return Promise.resolve(user);
    },

    async refreshToken() {
      if (this.user) {
        const refreshTokenRequest: RefreshTokenRequest = {
          refreshToken: this.user.refreshToken,
          userId: this.user.localId,
        };
        const refreshTokenResponse = await api
          .post(FUNCTION_URL_REFRESH_TOKEN, refreshTokenRequest)
          .then((response) => response.data)
          .then((response) => response as RefreshTokenResponse);

        const newUser: User = {
          ...this.user,
          refreshToken: refreshTokenResponse.refreshToken,
          idToken: refreshTokenResponse.idToken,
        };
        this.updateUser(newUser);
      }
    },
  },
});
