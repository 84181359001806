import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';

import routes from './routes';
import { useFirebaseStore } from 'src/stores/firebase';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  Router.beforeEach(async (to, from, next) => {
    const firebaseStore = useFirebaseStore();
    if (to.meta?.public) {
      next();
    } else if (to.name !== 'Login') {
      if (firebaseStore.currentUser) {
        next();
      } else {
        next({ name: 'Login', query: { redirect: to.path } });
      }
    } else {
      if (firebaseStore.currentUser) {
        next({ path: '' + (to.query?.redirect || '/') });
      } else {
        next();
      }
    }
  });

  return Router;
});
