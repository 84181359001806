import cloudflare from './cloudflare';
import firebase from './firebase';

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  update: 'Update',

  error: {
    undefined: 'Undefined error occurred',
    validator: {
      mandatory: 'Mandatory field',
      email: 'Not a valid email format.',
      length: 'Length must between {min} and {max}',
      range: 'Range must between {min} and {max}',
      notDate: 'Not a date.',
      match: 'Field not match: {field}',
      regex: 'Regex not match: {regex}',
    },
    type: {
      undefined: 'Unknown error',
      GENERIC_ERROR: 'Generic backend error',
      VALIDATION_ERROR: 'Validation error',
    },
    errorCode: {
      ...firebase,
      ...cloudflare,
    },
  },

  menu: {
    invite: 'Invite',
    users: 'Users',
    profile: 'Profile',
    logout: 'Log out',
  },

  common: {
    password: 'Password',
    submit: 'Submit',
    login: 'Login',
    send: 'Send',
    email: 'Email',
  },

  docNote: {
    INFO: 'Information',
    TIP: 'Tip',
    WARNING: 'WARNING',
    ERROR: 'Error',
  },

  login: {
    email: 'Usernam or email',
  },

  invite: {
    header: 'Invite administrator',
    body: "Register a new administrator. We will send notification email as well to verify its identity. Don't forget to ask client certificate as well.",
  },

  profile: {
    tabs: {
      myProfile: 'My Profile',
      changePassword: 'Change Password',
      verifyEmail: 'Verify Email',
    },
    verifyEmail: {
      verifyButtonLabel: 'Verify',
      successMessage:
        'We have sent you a verification email to: {email}. Please follow the instructions in the email to continue.',
    },
    changePassword: {
      oldPasswordLabel: 'Old Password',
      newPasswordLabel: 'New Password',
      confirmPasswordLabel: 'Confirm Password',
      passwordStrengthMessage: 'Your password is {strength}',
      message: {
        tooWeak: 'Too weak new password',
      },
      docNote: {
        p1: {
          text: 'This is an administrative page, therefore strong password usage is expected. Please choose your future password considering the following rules. It is recommended to use a password manager system.',
          rules: {
            length: 'The length must be at least 15 character',
            letter: 'Must contains at least one uppercase letter',
            number: 'Must contains at least one number',
            special:
              "Must contains at least one special character [# {'@'} ? , . ! + ~]",
            strong: "The password strenght have to be at least 'safe'",
          },
        },

        p2: {
          text: 'Here are some popular password manager solutions, mainly from free solutions',
          keepass:
            'KeePass: An open-source password manager that can be used offline.',
          lastpass:
            'LastPass: Has a free version that offers cloud-based storage.',
          bitwarden:
            'Bitwarden: An open-source solution with free and premium options.',
          dashlane:
            'Dashlane: Free version suitable for a limited number of password storage.',
          zoho: 'Zoho Vault: Free version for businesses that also allows password sharing.',
          nordpass: 'NordPass: Free basic version that offers secure storage.',
        },
      },
      strength: {
        risky: 'risky',
        guessable: 'guessable',
        weak: 'weak',
        safe: 'safe',
        secure: 'secure',
      },
    },
  },
};
